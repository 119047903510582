import React from 'react';

const SvgShareSecond = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={15.811}
      height={15.609}
      viewBox='0 0 16 16'
      {...props}
    >
      <g
        data-name='external-link (1)'
        fill='none'
        stroke='#6b7685'
        strokeLinecap='round'
        strokeWidth={1.5}
      >
        <path
          data-name='Path 5172'
          d='M12.95 8.353v4.88a1.627 1.627 0 01-1.626 1.626H2.377A1.627 1.627 0 01.75 13.233V4.286a1.627 1.627 0 011.627-1.627h4.88'
        />
        <path data-name='Path 5173' d='M9.966.75h4.88v4.88' />
        <path
          data-name='Line 311'
          strokeLinejoin='round'
          d='M5.75 10.219l9-9'
        />
      </g>
    </svg>
  )
}

export default SvgShareSecond
