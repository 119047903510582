import styled, { css } from 'styled-components'
import { theme } from '../../Theme'
import { rgba } from 'polished'
import InputMaskReact from 'react-input-mask'

interface IMultipleTags {
  hasMultipleTags?: boolean
  maxWidth?: string
}

interface SelectWrapperStyles {
  flex?: string
  disabled?: boolean
  disabledBackground?: string
}

export const SelectWrapper = styled.div<SelectWrapperStyles>`
  min-height: 40px;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  color: ${theme.palette.gray4};
  font-size: 14px;
  flex: ${(props) => (!props.flex ? '1' : props.flex)};

  ${(props) =>
    props.disabled &&
    css`
      input,
      div {
        background: ${props.disabledBackground ?? '#e9ecf3'};
        pointer-events: none;
        border-radius: ${theme.borderRadius.fields};
      }
    `}
`

interface ISelectGroupProps extends IMultipleTags {}
export const SelectGroup = styled.div<ISelectGroupProps>`
  width: 100%;
  position: relative;
  ${({ hasMultipleTags }) =>
    hasMultipleTags &&
    css`
      height: 40px;
    `}
`

export const CountResults = styled.div`
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Inter', sans-serif;
  color: #5a6a7e;
  padding: ${theme.spacing.base};
`

export const CountResultsText = styled.div`
  font-weight: bold;
`

export const AreaCheck = styled.div`
  margin-top: 3px;
`

interface IInputWrapper extends IMultipleTags {
  error: boolean
  focused: boolean
}
export const InputWrapper = styled.div<IInputWrapper>`
  border-radius: ${theme.borderRadius.fields};
  border: ${({ error }) =>
      error ? theme.palette.highlight.red : theme.palette.gray2}
    1px solid;
  background-color: ${theme.palette.white};
  display: flex;
  width: 100%;
  ${({ error, focused }) =>
    !error &&
    focused &&
    css`
      border: 1px solid ${theme.colors.blue3};
      -webkit-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
      -moz-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
      box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    `}
  ${({ error }) =>
    error
      ? `box-shadow: 0px 0px 6px 0px ${theme.palette.highlight.red}4D`
      : null};
  cursor: pointer;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth} !important;
    `};
  ${({ hasMultipleTags }) =>
    hasMultipleTags &&
    css`
      min-width: 100%;
    `}
`

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  width: auto;
`
export const InputMask = styled(InputMaskReact)<IMultipleTags>`
  outline: none;
  width: calc(100% - 60px);
  height: 38px;
  padding: 0px;
  margin: 0px ${theme.spacing.base};
  border: none;
  margin-left: 16px;
  color: ${theme.palette.black};
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  cursor: ${(props) => {
    return props.readOnly ? 'default' : 'text'
  }};
  ::placeholder {
    color: ${theme.palette.gray4};
    font-size: 14px;
    opacity: 1;
  }
  ${({ hasMultipleTags }) =>
    hasMultipleTags &&
    css`
      min-width: 30px;
    `}
`

export const Input = styled.input<IMultipleTags>`
  outline: none;
  width: calc(100% - 60px);
  height: 38px;
  padding: 0px;
  margin: 0px ${theme.spacing.base};
  border: none;
  margin-left: 16px;
  color: ${theme.palette.black};
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  cursor: ${(props) => {
    return props.readOnly ? 'default' : 'text'
  }};
  ::placeholder {
    color: ${theme.palette.gray4};
    font-size: 14px;
    opacity: 1;
  }
  ${({ hasMultipleTags }) =>
    hasMultipleTags &&
    css`
      min-width: 30px;
    `}
`
export const ErrorWrapper = styled.div`
  text-align: left;
  position: absolute;
  bottom: -25px;
  margin: ${theme.spacing.xSmall} 0px;
  font-size: 12px;
  min-height: 12px;
  line-height: 12px;
  color: ${theme.palette.highlight.red};
`

const openOptions = {
  up: css`
    bottom: 40px;
    box-shadow: 3px 0px 10px -2px ${theme.palette.gray5};
  `,
  down: css`
    top: 37px;
    box-shadow: 3px 10px 10px -2px ${theme.palette.gray5};
  `
}
interface IOptionsWrapperProps extends IMultipleTags {
  isDisplayOptions: boolean
  isOptionsOpen: boolean
  maxHeight: string
  openDirection: 'up' | 'down'
}
export const OptionsWrapper = styled.div<IOptionsWrapperProps>`
  position: absolute;
  ${({ hasMultipleTags, openDirection }) =>
    !hasMultipleTags && openDirection && openOptions[openDirection]}
  z-index: 10;
  width: 100%;
  border: ${({ isOptionsOpen, isDisplayOptions }) =>
    isOptionsOpen && isDisplayOptions
      ? `${theme.palette.gray2} 1px solid`
      : 'none'};
  background-color: ${theme.palette.white};
  border-bottom-left-radius: ${theme.borderRadius.fields};
  border-bottom-right-radius: ${theme.borderRadius.fields};
  overflow: auto;
  max-height: 230px;
  height: ${({ isOptionsOpen }) => (isOptionsOpen ? 'auto' : '0px')};
  ${({ maxHeight }) =>
    css`
      max-height: ${maxHeight} !important;
    `};
  display: ${({ isOptionsOpen }) => (isOptionsOpen ? '' : 'none')};
  visibility: ${({ isOptionsOpen }) => (isOptionsOpen ? 'visible' : 'hidden')};
`

export interface IOptionColor {
  backgroundColorOnHover?: string
  colorOnHover?: string
  selectedBackgroundColor?: string
  selectedColor?: string
}

interface IOption extends IOptionColor {
  selected?: boolean
  optionWithKeyDown: boolean
}

export const Option = styled.div<IOption>`
  padding: 5px ${theme.spacing.base};
  cursor: pointer;
  background-color: ${({ optionWithKeyDown, backgroundColorOnHover }) =>
    optionWithKeyDown && `${backgroundColorOnHover ?? theme.palette.gray2}`};
  transition: 200ms all;
  display: flex;
  line-height: 25px;
  &:hover {
    background-color: ${({ backgroundColorOnHover }) =>
      `${backgroundColorOnHover ?? theme.palette.gray5}`};
    color: ${({ colorOnHover }) => `${colorOnHover ?? theme.palette.gray4}`};
  }
  ${({ selected, selectedBackgroundColor, optionWithKeyDown, selectedColor }) =>
    !optionWithKeyDown &&
    selected &&
    css`
      background-color: ${selectedBackgroundColor ?? theme.palette.gray3};
      color: ${selectedColor ?? theme.palette.gray4};
    `}
`

export const NoOptionFound = styled.div`
  padding: 0px ${theme.spacing.base};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`
export const LoadingContainer = styled.div`
  padding: 50px ${theme.spacing.base};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`

interface IIconWrapper {
  rotate: string
  error: boolean
  iconLeft?: boolean
  hasMultipleTags?: boolean
}
export const IconWrapper = styled.div<IIconWrapper>`
  display: flex;
  margin-right: ${theme.spacing.base};
  flex-grow: 1;
  max-height: ${({ hasMultipleTags }) => hasMultipleTags && '40px'};
  align-items: center;
  transform: rotate(0deg);
  transition: all 0.3s ease-out;
  transform-origin: top 0 bottom;
  transform: ${({ rotate }) =>
    rotate === 'true' ? 'rotate3d(1, 0, 0, -0.5turn)' : ''};
  color: ${({ error }) =>
    error ? theme.palette.highlight.red : theme.palette.button.icons};
  cursor: pointer;
  ${({ iconLeft }) =>
    iconLeft &&
    css`
      margin-left: ${theme.spacing.base};
      margin-right: 0;
    `}
`
export const ErrorIconWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  color: ${theme.palette.highlight.red};
`
interface ITagsContainerProps extends IMultipleTags {}
export const TagsContainer = styled.div<ITagsContainerProps>`
  width: 100%;
  ${({ hasMultipleTags }) =>
    hasMultipleTags &&
    css`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        margin: ${theme.spacing.xSmall} ${theme.spacing.tiny};
      }
    `}
`
