import React from 'react'

const Patients = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.171'
      height='17.6'
      viewBox='0 0 22.171 17.6'
      {...props}
    >
      <g
        id='Grupo_163732'
        data-name='Grupo 163732'
        transform='translate(-24.2 -10.2)'
      >
        <g
          id='Users_Circle'
          data-name='Users, Circle'
          transform='translate(22 7)'
        >
          <path
            id='Caminho_27812'
            data-name='Caminho 27812'
            d='M3,18.571H3A4.572,4.572,0,0,1,7.571,14h4.571a4.572,4.572,0,0,1,4.571,4.571h0'
            transform='translate(0 1.429)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.6'
          />
          <path
            id='Caminho_27813'
            data-name='Caminho 27813'
            d='M16,4.651a2.857,2.857,0,1,1,0,5.714'
            transform='translate(1.857 0.093)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.6'
          />
          <path
            id='Caminho_27814'
            data-name='Caminho 27814'
            d='M12.234,5.138a3.887,3.887,0,1,1-5.5,0,3.887,3.887,0,0,1,5.5,0'
            transform='translate(0.371)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.6'
          />
          <path
            id='Caminho_27815'
            data-name='Caminho 27815'
            d='M17,13a4.572,4.572,0,0,1,4.571,4.571h0'
            transform='translate(2 1.286)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.6'
          />
        </g>
      </g>
    </svg>
  )
}

export default Patients
