import React from 'react'

const HHistory = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='21'
      height='22'
      viewBox='0 0 21 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.52798 18.428H2.75198C2.28887 18.4238 1.84592 18.238 1.51845 17.9105C1.19097 17.583 1.00515 17.1401 1.00098 16.677L1.00098 3.25198C1.00515 2.78887 1.19097 2.34592 1.51845 2.01845C1.84592 1.69097 2.28887 1.50515 2.75198 1.50098L17.186 1.50098C17.6491 1.50515 18.092 1.69097 18.4195 2.01845C18.747 2.34592 18.9328 2.78887 18.937 3.25198L18.937 9.93798'
        stroke='#9AA8BA'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M12.536 20.9749H10.124C10.0865 20.9791 10.0485 20.9751 10.0126 20.9634C9.97679 20.9517 9.94384 20.9325 9.91601 20.907C9.88818 20.8816 9.8661 20.8504 9.85126 20.8158C9.83642 20.7811 9.82916 20.7436 9.82995 20.7059L9.82995 16.5709C9.82973 16.5335 9.83742 16.4965 9.85251 16.4623C9.8676 16.4281 9.88976 16.3975 9.91753 16.3725C9.94529 16.3475 9.97804 16.3286 10.0136 16.3171C10.0492 16.3056 10.0868 16.3018 10.124 16.3059H12.536C12.5731 16.3018 12.6107 16.3056 12.6463 16.3171C12.6819 16.3286 12.7146 16.3475 12.7424 16.3725C12.7701 16.3975 12.7923 16.4281 12.8074 16.4623C12.8225 16.4965 12.8302 16.5335 12.83 16.5709V20.7059C12.8224 20.779 12.7883 20.8467 12.7341 20.8963C12.6799 20.9459 12.6094 20.9738 12.536 20.9749Z'
        stroke='#9AA8BA'
        strokeWidth='1.5'
      />
      <path
        d='M16.656 20.974H13.1C13.0311 20.9695 12.9663 20.9401 12.9175 20.8914C12.8688 20.8426 12.8394 20.7778 12.835 20.709L12.835 12.006C12.8394 11.9372 12.8688 11.8723 12.9175 11.8236C12.9663 11.7748 13.0311 11.7454 13.1 11.741L16.6 11.741C16.6688 11.7454 16.7336 11.7748 16.7824 11.8236C16.8311 11.8723 16.8605 11.9372 16.865 12.006V20.706C16.8721 20.7691 16.8542 20.8325 16.8151 20.8826C16.776 20.9327 16.7189 20.9655 16.656 20.974Z'
        stroke='#9AA8BA'
        strokeWidth='1.5'
      />
      <path
        d='M19.5741 20.9761H17.2391C17.19 20.9772 17.1412 20.9683 17.0956 20.95C17.05 20.9317 17.0086 20.9043 16.974 20.8695C16.9393 20.8347 16.912 20.7933 16.8938 20.7477C16.8756 20.702 16.8669 20.6532 16.8681 20.6041V14.7671C16.867 14.7181 16.8759 14.6693 16.8941 14.6238C16.9124 14.5783 16.9397 14.537 16.9743 14.5023C17.009 14.4676 17.0503 14.4404 17.0958 14.4221C17.1413 14.4038 17.1901 14.395 17.2391 14.3961H19.5741C19.6231 14.395 19.6718 14.4038 19.7174 14.4221C19.7629 14.4404 19.8042 14.4676 19.8389 14.5023C19.8735 14.537 19.9008 14.5783 19.9191 14.6238C19.9373 14.6693 19.9462 14.7181 19.9451 14.7671V20.6041C19.9463 20.6532 19.9376 20.702 19.9194 20.7477C19.9012 20.7933 19.8739 20.8347 19.8392 20.8695C19.8045 20.9043 19.7632 20.9317 19.7176 20.95C19.672 20.9683 19.6232 20.9772 19.5741 20.9761Z'
        stroke='#9AA8BA'
        strokeWidth='1.5'
      />
      <path
        d='M4.71499 6.75395C5.21315 6.75395 5.61699 6.35011 5.61699 5.85195C5.61699 5.35379 5.21315 4.94995 4.71499 4.94995C4.21683 4.94995 3.81299 5.35379 3.81299 5.85195C3.81299 6.35011 4.21683 6.75395 4.71499 6.75395Z'
        fill='#9AA8BA'
      />
      <path
        d='M4.71499 10.8399C5.21315 10.8399 5.61699 10.436 5.61699 9.93789C5.61699 9.43973 5.21315 9.03589 4.71499 9.03589C4.21683 9.03589 3.81299 9.43973 3.81299 9.93789C3.81299 10.436 4.21683 10.8399 4.71499 10.8399Z'
        fill='#9AA8BA'
      />
      <path
        d='M7.63397 5.85291L14.692 5.85291'
        stroke='#9AA8BA'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M7.63397 9.93896H10.553'
        stroke='#9AA8BA'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default HHistory
