import React from 'react'

const Maximize = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.765'
      height='13.765'
      viewBox='0 0 13.765 13.765'
    >
      <g id='maximize-2' transform='translate(0.75 0.75)'>
        <g id='Grupo_163439' data-name='Grupo 163439'>
          <path
            id='Caminho_27593'
            data-name='Caminho 27593'
            d='M15,3h4.454V7.454'
            transform='translate(-7.189 -3)'
            fill='none'
            stroke='#1c7cf9'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <path
            id='Caminho_27594'
            data-name='Caminho 27594'
            d='M7.454,19.454H3V15'
            transform='translate(-3 -7.189)'
            fill='none'
            stroke='#1c7cf9'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            id='Linha_1299'
            data-name='Linha 1299'
            x1='4'
            y2='4'
            transform='translate(7.451 0.813)'
            fill='none'
            stroke='#1c7cf9'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <line
            id='Linha_1300'
            data-name='Linha 1300'
            y1='4'
            x2='4'
            transform='translate(0.451 7.813)'
            fill='none'
            stroke='#1c7cf9'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  )
}

export default Maximize
