import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

export interface InputWrapperProps {
  errors?: boolean
  disabled?: boolean
  active?: boolean
  error?: boolean | string
  direction?: string | string
  icon?: string
  iconPosition?: string
}

export const InputWrapper = styled.input<InputWrapperProps>`
  ${(props) =>
    css`
      padding: 8px 16px;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      color: ${theme.colors.black};
      transition: all 0.2s ease-in-out;
      border-radius: ${theme.borderRadius.fields};
      width: 100%;
      outline: none;
      height: 40px;
      border: 1px solid ${theme.colors.gray3};
      padding-left: ${props.icon && props.iconPosition === 'left'
        ? '44px'
        : ''};
      padding-right: ${props.icon && props.iconPosition === 'right'
        ? '44px'
        : ''};

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      ::-webkit-calendar-picker-indicator {
        filter: invert(60%);
      }
      ::-webkit-datetime-edit-fields-wrapper {
        font-family: 'Inter', sans-serif;
      }
      ${props.errors &&
      css`
        border: 1px solid ${theme.colors.red1};
      `}
      ${!props.errors &&
      props.active &&
      css`
        border: 1px solid ${theme.colors.blue3};
      `}

  ${props.disabled &&
      css`
        background: #e9ecf3;
        border: 1px solid #d4d9de;
        pointer-events: none;
      `}

  &::placeholder {
        color: ${theme.colors.gray5};
        opacity: 0.5;
      }

      &::-ms-input-placeholder {
        color: ${theme.colors.gray5};
        opacity: 0.5;
      }

      &::-ms-input-placeholder {
        color: ${theme.colors.gray5};
        opacity: 0.5;
      }
    `}
`

interface InputContainer {
  margin?: string
}

export const InputContainer = styled.div<InputContainer>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ margin }) => margin};
  border-radius: 4px;
  position: relative;
  color: ${theme.palette.button.icons};
  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`

export const Text = styled.p`
  margin: 0px 5px;
  font-size: 14px;
`

const stateInput = {
  error: css`
    color: ${theme.colors.gray4};

    svg {
      fill: ${theme.colors.red1};
    }
  `,
  active: css`
    -webkit-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    -moz-box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
    box-shadow: 0px 0px 6px 0px ${rgba(theme.colors.blue3, 0.4)};
  `
}

export const WrapperLight = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: ${(props) => props.direction && props.direction};
  align-items: center;
  border-radius: ${theme.borderRadius.fields};

  svg {
    fill: transparent;
    position: absolute;
    right: ${(props) => (props.iconPosition === 'right' ? '14px' : '')};
    left: ${(props) => (props.iconPosition === 'left' ? '14px' : '')};
  }
  ${({ active }) => (active ? stateInput.active : null)};
  ${({ error }) => (error ? stateInput.error : null)}
`

export const ErrorContainer = styled.span`
  color: ${theme.colors.red1};
  font-size: 12px;
  padding-top: 4px;
  position: absolute;
  top: 100%;
`
