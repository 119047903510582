import styled, { css } from 'styled-components'
import { CellStyle } from '../Body/styles'

export interface HeaderStyleProps {
  lineHeight?: string
  padding?: string
  alignItems?: string
}

export interface HeaderCellProps {
  clickable?: boolean | undefined
}

export const HeaderStyle = styled.div<HeaderStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || 'auto'};

  min-width: fit-content;
  width: 100%;
  height: ${({ lineHeight }) => lineHeight || '47px'};

  border-radius: 4px;
  background-color: #f1f4f6;
  padding: ${({ padding }) => padding || '16px 0 16px 24px'};
  box-sizing: border-box;

  font-size: 12px;
  color: #5a6a7e;
`

export const HeaderCell = styled(CellStyle)<HeaderCellProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

export const SortIconContainer = styled.div`
  padding: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

export const AreaCheck = styled.div`
  > span > span {
    left: -15px;
    padding: 6px 12px;
  }
`
