import { DatePicker } from '@cockpit/levi-ui'
import styled, { css } from 'styled-components'

interface maskField {
  show: boolean
  type?: 'time' | 'date' | 'basic' | 'calendar'
  radio?: boolean
}

export const FieldInput = styled.div<maskField>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  width: ${({ show, type }) =>
    type === 'calendar' ? '337' : show ? '247' : '273'}px;
  height: 64px;

  @media (max-width: 750px) {
    width: ${({ show, type }) =>
      type === 'calendar' ? '303' : show ? '247' : '273'}px;
    column-gap: 10px;
  }
`

const sizeLabel = {
  time: () => '104px',
  date: () => '104px',
  calendar: () => 'calc(50% - 19.5px)',
  basic: () => 'calc(50% - 19.5px)'
}

export const FieldPeriod = styled.div<maskField>`
  width: ${({ show, type }) => (show ? sizeLabel[type ?? 'basic']() : '100%')};
  ${({ radio, type }) =>
    radio
      ? css`
          div {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 2px;
          }
        `
      : css`
          height: 40px;
          input,
          input:hover {
            padding: ${type === 'date' ? '0px 8px' : '0px 16px'};
          }
        `}
  @media(max-width: 300px) {
    ${({ show, type }) =>
      show &&
      type === 'date' &&
      css`
        width: 73px;
      `}
  }
`

export const DatePickerContainer = styled.div`
  p {
    position: relative;
    top: 0px;
    line-height: 1.05;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  input {
    padding: 0px !important;
  }
`
