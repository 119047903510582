import React from 'react'

const Finance = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={22}
      height={22}
      viewBox='0 0 22 22'
      fill='none'
      {...props}
    >
      <path
        d='M11 4.33301V6.00001M11 17.667V16M7.66698 13.506C7.66698 14.169 7.93037 14.8049 8.39921 15.2738C8.86805 15.7426 9.50394 16.006 10.167 16.006H11.993C12.5637 16.0055 13.1146 15.7964 13.5421 15.4182C13.9696 15.04 14.2441 14.5187 14.3141 13.9522C14.3842 13.3858 14.2448 12.8132 13.9223 12.3423C13.5999 11.8714 13.1164 11.5345 12.563 11.395L9.43898 10.612C8.8847 10.4733 8.40027 10.1367 8.07697 9.66562C7.75367 9.19451 7.61382 8.62143 7.68379 8.05436C7.75375 7.48729 8.02868 6.96538 8.45679 6.58698C8.88489 6.20858 9.43661 5.99981 10.008 6.00001H11.834C12.497 6.00001 13.1329 6.2634 13.6017 6.73224C14.0706 7.20108 14.334 7.83696 14.334 8.50001M18.072 3.92901C19.4705 5.32752 20.423 7.10935 20.8088 9.04917C21.1947 10.989 20.9967 12.9997 20.2398 14.8269C19.4829 16.6542 18.2012 18.216 16.5567 19.3148C14.9122 20.4136 12.9788 21.0001 11.001 21.0001C9.02316 21.0001 7.08975 20.4136 5.44526 19.3148C3.80076 18.216 2.51904 16.6542 1.76217 14.8269C1.0053 12.9997 0.807275 10.989 1.19314 9.04917C1.57901 7.10935 2.53144 5.32752 3.92998 3.92901C5.8053 2.05399 8.34859 1.00064 11.0005 1.00064C13.6524 1.00064 16.1957 2.05399 18.071 3.92901H18.072Z'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Finance
