import React from 'react'

const TimeNotifications = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19.034}
    height={19.078}
    viewBox='0 0 21 21'
    {...props}
  >
    <path d='M9.498 0a9.536 9.536 0 00-7.043 3.148V.985a.75.75 0 00-.75-.75.75.75 0 00-.75.75v4.787a.75.75 0 00.75.75h4.554a.75.75 0 00.75-.75.75.75 0 00-.75-.75H2.881A8.034 8.034 0 019.498 1.5a8.048 8.048 0 018.036 8.039 8.049 8.049 0 01-8.039 8.039 8.015 8.015 0 01-8-7.236.739.739 0 00-.822-.671.75.75 0 00-.671.822 9.512 9.512 0 009.491 8.585 9.551 9.551 0 009.539-9.539A9.551 9.551 0 009.498 0z' />
    <path d='M9.071 4.997a.756.756 0 00-.756.757v5.544a.755.755 0 00.381.657l3.53 2.017a.755.755 0 001.031-.282.756.756 0 00-.281-1.031l-3.149-1.8v-5.1a.757.757 0 00-.756-.762z' />
    <g
      id='Elipse_1894'
      data-name='Elipse 1894'
      transform='translate(12 -2)'
      fill='#d93b2a'
      stroke='#122d52'
      strokeWidth='1.5'
    >
      <circle cx='5' cy='5' r='5' stroke='none' />
      <circle cx='5' cy='5' r='4.25' fill='none' />
    </g>
  </svg>
)

export default TimeNotifications
