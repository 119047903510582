import React from 'react'
import { theme } from '../../Theme'

const StopButton = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
    >
      <g transform='translate(-1040 -613)'>
        <circle
          cx='12'
          cy='12'
          r='12'
          transform='translate(1040 613)'
          fill={theme.colors.blue11}
          opacity='0.197'
        />
        <g transform='translate(-1.5 -1.07)'>
          <line
            y2='8'
            transform='translate(1051.5 622.07)'
            fill='none'
            stroke={theme.palette.button.primary}
            strokeLinecap='round'
            strokeWidth='1.8'
          />
          <line
            y2='8'
            transform='translate(1055.5 622.07)'
            fill='none'
            stroke={theme.palette.button.primary}
            strokeLinecap='round'
            strokeWidth='1.8'
          />
        </g>
      </g>
    </svg>
  )
}

export default StopButton
