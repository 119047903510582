import React from 'react'

const Maximize = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='16'
      viewBox='0 0 18 16'
    >
      <path
        id='Caminho_27596'
        data-name='Caminho 27596'
        d='M353.327,386.606H339.766a2.292,2.292,0,0,1-2.3-2.282V372.736a2.139,2.139,0,0,1,2.143-2.13h4.363a2.159,2.159,0,0,1,1.715.852l1.153,1.527a.338.338,0,0,0,.27.135h6.214a2.138,2.138,0,0,1,2.143,2.129v9.228A2.139,2.139,0,0,1,353.327,386.606Zm-13.714-14.617a.75.75,0,0,0-.751.747v11.588a.9.9,0,0,0,.9.9h13.561a.749.749,0,0,0,.751-.746v-9.228a.749.749,0,0,0-.751-.746h-6.214a1.74,1.74,0,0,1-1.384-.689l-1.153-1.526a.756.756,0,0,0-.6-.3Z'
        transform='translate(-337.47 -370.606)'
        fill='#6b7786'
      />
    </svg>
  )
}

export default Maximize
