import React from 'react'

const Inactive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19.5'
      height='17.5'
      viewBox='0 0 19.5 17.5'
      {...props}
    >
      <g
        id='Grupo_164060'
        data-name='Grupo 164060'
        transform='translate(-1106.25 -179.25)'
      >
        <g
          id='Box_-_cópia'
          data-name='Box - cópia'
          transform='translate(1105 177)'
        >
          <path
            id='Caminho_28001'
            data-name='Caminho 28001'
            d='M19.1,6.551H2.9A.894.894,0,0,1,2,5.663V3.888A.894.894,0,0,1,2.9,3H19.1a.894.894,0,0,1,.9.888V5.663A.894.894,0,0,1,19.1,6.551Z'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
            fillRule='evenodd'
          />
          <path
            id='Caminho_28002'
            data-name='Caminho 28002'
            d='M9,11h5.327'
            transform='translate(-0.663 -0.889)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
          <path
            id='Caminho_28003'
            data-name='Caminho 28003'
            d='M18.966,7V17.654a1.789,1.789,0,0,1-1.8,1.776H5.137a1.789,1.789,0,0,1-1.8-1.776V7'
            transform='translate(-0.15 -0.429)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </svg>
  )
}

export default Inactive
