import { Link } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { IPropsTheme } from '../../Theme'

const skeletonLoading = keyframes`
 0% { background-color: hsl(200, 10%, 50%) }
 100% { background-color: hsl(200, 10%, 65%) }
`

interface IButtonOption {
  isButtonActive: boolean
}

interface IButtonMore {
  active: boolean
}

export const SidebarContent = styled.nav<{ isopen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: fixed;
  left: 0;
  top: 0;
  width: ${({ isopen }) => (isopen ? '250px' : '56px')};
  height: 100%;
  z-index: 9999;
  margin: 0;
  padding: ${({ isopen }) => (isopen ? '10px 0' : '10px 3px')};
  padding-top: 0px;
  box-shadow: 0 3px 6px #00000029;
  background: ${({ theme }: IPropsTheme) => theme.menu.background};
  transition: width 0.5s, padding 0.2s;
`

interface IsolatedMenuContainerProps {
  positionEnd?: boolean
}
export const IsolatedMenuContainer = styled.div<IsolatedMenuContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.positionEnd &&
    css`
      justify-content: flex-end;
      flex: 1;
      margin-bottom: 14px;
    `}
`

export const Divider = styled.span`
  height: 1px;
  width: 100%;
  background: ${({ theme }: IPropsTheme) => theme.menu.divider};
  margin-top: 15px;
`

export const AreaSeparator = styled.div`
  width: 100%;
  padding: 20px 5px;
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }: IPropsTheme) =>
    theme.menu.iconContainerColor};
`

export const MoreOptions = styled.div`
  display: block;
  width: 100%;
  position: relative;
`

export const ButtonMore = styled.button<IButtonMore>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  border: 0px solid;
  font-weight: ${({ theme }: IPropsTheme) => theme.fontWeight.regular};
  font-family: ${({ theme }: IPropsTheme) => theme.font.root};
  color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
  cursor: pointer;
  transition: all 0.2s linear;
  position: relative;
  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
    `}
  &:hover {
    color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColorHover};
  }
`

export const MenuMoreOptions = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 225px;
  padding: 10px 2px;
  position: absolute;
  left: calc(100% + 10px);
  top: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 20px #00000029;
  border: 1px solid #e9ecf3;
  border-radius: 4px;
  opacity: 1;
  pointer-events: initial;
`

export const LinkOption = styled(Link)<IButtonOption>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
  transition: all 0.2s linear;
  text-decoration: none;
  color: #9ba8ba;
  width: 100%;
  ${({ isButtonActive }) =>
    isButtonActive &&
    css`
      background: #e9ecf3 0% 0% no-repeat padding-box;
    `}
  &:hover {
    background: #e9ecf3 0% 0% no-repeat padding-box;
    span {
      opacity: 1;
    }
  }
`

export const ButtonOption = styled.button<IButtonOption>`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
  transition: all 0.2s linear;
  text-decoration: none;
  color: #9ba8ba;
  width: 100%;
  ${({ isButtonActive }) =>
    isButtonActive &&
    css`
      background: #e9ecf3 0% 0% no-repeat padding-box;
    `}
  &:hover {
    background: #e9ecf3 0% 0% no-repeat padding-box;
    span {
      opacity: 1;
    }
  }
`

export const TitleOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Inter, sans-serif;
  color: #000000;
  opacity: 1;
`

export const AreaIconLink = styled.div<{ invert?: boolean }>`
  margin: 0 9px;

  ${({ invert }) =>
    !invert
      ? css`
          svg {
            color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
            fill: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
          }
        `
      : css`
          svg {
            color: ${({ theme }: IPropsTheme) => theme.menu.iconContainerColor};
            stroke: ${({ theme }: IPropsTheme) =>
              theme.menu.iconContainerColor};
          }
        `}
`

export const AreaIconDrag = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  cursor: move;
  opacity: 1;
  @media (min-width: 768px) {
    opacity: 0;
  }
`

export const SkeletonIcon = styled.div`
  width: 100%;
  height: 35px;
  border-radius: 5px;
  margin-bottom: 20px;
  opacity: 0.35;
  animation: ${skeletonLoading} 0.7s linear infinite alternate;
`

export const AreaLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 7.5px;
  margin-top: 20px;
`
