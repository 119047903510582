/* eslint-disable no-debugger */
import styled, { css } from 'styled-components'
import { theme } from '../Theme'

interface StylesProps {
  width?: string
}

interface Button {
  color?: 'primary' | 'success' | 'error' | 'warning' | 'disabled'
  variant?: 'outlined' | 'contained'
  loading?: boolean
  minWidth?: 'small' | 'medium' | 'large' | 'auto'
  styles: StylesProps
  disabled?: boolean
}

export const ButtonWrapper = styled.button<Button>`
  ${({ color = 'primary', variant = 'contained' }: Button) => css`
    background-color: ${variant === 'outlined'
      ? 'transparent'
      : theme.palette.button[color]};
    border: 1px solid
      ${(props) => (props.disabled ? '#6B7786' : theme.palette.button[color])};
    color: ${variant === 'outlined'
      ? theme.palette.button[color]
      : theme.palette.white};
    canvas {
      background: ${variant === 'contained'
        ? theme.palette.white
        : theme.palette.button[color]};
    }

    ${(props) =>
      props.disabled &&
      css`
        opacity: 40%;
        background-color: ${props.variant === 'outlined'
          ? 'transparent'
          : '#6B7786'};
        color: ${props.variant === 'outlined' ? '#6B7786' : '#fff'};
        border: 1px solid '#6B7786';
      `};

    :hover {
      background-color: ${(props: Button) =>
        props.color === 'disabled'
          ? 'theme.palette.button[color]'
          : variant === 'outlined'
          ? theme.palette.button[color]
          : color === 'primary'
          ? theme.palette.button.mainHover
          : theme.palette.button[color]};
      color: ${(props: Button) =>
        props.color === 'disabled'
          ? theme.palette.white
          : variant === 'outlined'
          ? theme.palette.white
          : theme.palette.white};

      ${(props) =>
        props.disabled &&
        css`
          background-color: ${props.variant === 'outlined'
            ? 'transparent'
            : '#6B7786'};
          color: ${props.variant === 'outlined' ? '#6B7786' : '#fff'};
        `};

      ${(props) =>
        props.loading &&
        css`
          background-color: ${props.variant === 'outlined'
            ? 'transparent'
            : theme.palette.button[color]};
        `}
    }
    canvas {
      background: ${variant === 'contained'
        ? theme.palette.button[color]
        : theme.palette.white};
    }
  `};

  font-family: ${theme.font.root};
  font-weight: ${theme.fontWeight.semibold};
  line-height: 0%;
  height: 40px;
  font-size: 14px;
  padding: 12px ${theme.spacing.medium};
  border-radius: ${theme.borderRadius.buttons};
  cursor: ${(props: Button) =>
    props.color === 'disabled' ? 'not-allowed' : 'pointer'};
  outline: none;
  transition: all 0.2s;
  text-align: center;
  min-width: ${(props) =>
    props.minWidth === 'small'
      ? '100px'
      : props.minWidth === 'medium'
      ? '180px'
      : props.minWidth === 'large'
      ? '200px'
      : 'auto'};
  width: ${(props: Button) => props.styles.width};
`
export const ChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
