import React from 'react'

const Active = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.5'
      height='17.5'
      viewBox='0 0 15.5 17.5'
      {...props}
    >
      <g
        id='Users_Circle'
        data-name='Users, Circle'
        transform='translate(-2.25 -3.25)'
      >
        <path
          id='Caminho_27812'
          data-name='Caminho 27812'
          d='M3,18.571H3A4.62,4.62,0,0,1,7.667,14h4.667A4.62,4.62,0,0,1,17,18.571h0'
          transform='translate(0 1.429)'
          fill='none'
          stroke='#969ba7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
        <path
          id='Caminho_27814'
          data-name='Caminho 27814'
          d='M12.373,5.138a3.831,3.831,0,0,1,0,5.5,4.027,4.027,0,0,1-5.611,0,3.831,3.831,0,0,1,0-5.5,4.027,4.027,0,0,1,5.611,0'
          transform='translate(0.433)'
          fill='none'
          stroke='#969ba7'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
        />
      </g>
    </svg>
  )
}

export default Active
