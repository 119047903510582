import { default as attention_triangle } from './AttentionTriangle'
import { default as attention_rounded } from './AttentionRounded'
import { default as calendar } from './Calendar'
import { default as calendar_alt } from './CalendarAlt'
import { default as camera } from './Camera'
import { default as chat_doctor } from './ChatDoctor'
import { default as chat_person } from './ChatPerson'
import { default as check_rounded } from './CheckRounded'
import { default as check_on } from './Checkdisable'
import { default as check_disable } from './Checkon'
import { default as check } from './Check'
import { default as check2 } from './Check2'
import { default as checklist_health } from './ChecklistHealth'
import { default as chevron } from './Chevron'
import { default as chevron_open } from './ChevronOpen'
import { default as chevron_up_down } from './ChevronUpDown'
import { default as clipboard } from './Clipboard'
import { default as clipboard_check } from './ClipboardCheck'
import { default as clipboard_patient } from './ClipboardPatient'
import { default as close_rounded } from './CloseRounded'
import { default as close } from './Close'
import { default as doctor } from './Doctor'
import { default as download } from './Download'
import { default as edit_cc } from './EditCorpoClinico'
import { default as fila } from './Fila'
import { default as file_text } from './FileText'
import { default as hamburguer } from './Hamburguer'
import { default as hospital } from './Hospital'
import { default as identity } from './Identity'
import { default as monitor_doctor } from './MonitorDoctor'
import { default as monitor } from './Monitor'
import { default as pencil_rounded } from './PencilRounded'
import { default as queue } from './Queue'
import { default as reload } from './Reload'
import { default as reload_cc } from './ReloadCorpoClinico'
import { default as schedule } from './Schedule'
import { default as search } from './Search'
import { default as share } from './Share'
import { default as share_second } from './ShareSecond'
import { default as stethoscope } from './Stethoscope'
import { default as surdo } from './Surdo'
import { default as trash } from './Trash'
import { default as time_24 } from './Time24'
import { default as time_rounded_alt } from './TimeRoundedAlt'
import { default as time_rounded } from './TimeRounded'
import { default as time } from './Time'
import { default as user } from './User'
import { default as users } from './Users'
import { default as up_right } from './CornerUpRight'
import { default as warning } from './Warning'
import { default as icon_edit } from './IconEdit'
import { default as support } from './Support'
import { default as arrow_left } from './ArrowLeftIcon'
import { default as arrow_right } from './ArrowRightIcon'
import { default as reason_ear } from './ReasonEar'
import { default as clinics } from './Clinics'
import { default as pdf_icon } from './PdfIcon'
import { default as pront_icon } from './ProntIcon'
import { default as dots } from './Dots'
import { default as whats_icon } from './WhatsIcon'
import { default as edit_icon } from './EditIcon'
import { default as message_icon } from './MessageIcon'
import { default as profile_icon } from './ProfileIcon'
import { default as sliders } from './Sliders'
import { default as info_icon } from './InfoIcon'
import { default as load } from './Load'
import { default as register_icon } from './RegistroIcon'
import { default as clipboard_text } from './ClipboardText'
import { default as attendence } from './Attendence'
import { default as presential } from './Presential'
import { default as conf_video } from './ConfVideo'
import { default as checked } from './Checked'
import { default as error_icon } from './ErrorIcon'
import { default as loading_icon } from './LoadingIcon'
import { default as send_check } from './SendCheckIcon'
import { default as interrogation } from './Interrogation'
import { default as forward } from './Forward'
import { default as double_left_arrow } from './DoubleLeftArrow'
import { default as logo_cockpit } from './LogoCockpit'
import { default as home } from './Home'
import { default as prescription_icon } from './PrescriptionIcon'
import { default as custom_icon } from './CustomIcon'
import { default as medical_record } from './MedicalRecord'
import { default as exam_icon } from './ExamIcon'
import { default as bold_paper } from './BoldPaper'
import { default as check3 } from './Check3'
import { default as zoom_in } from './ZoomInIcon'
import { default as zoom_out } from './ZoomOutIcon'
import { default as sound_on } from './SoundOn'
import { default as sound_off } from './SoundOff'
import { default as external_link } from './ExternalLink'
import { default as star_icon } from './SvgStarIcon'
import { default as plus } from './Plus'
import { default as plus_document } from './PlusDocument'
import { default as eye_on } from './EyeOnIcon'
import { default as eye_off } from './EyeOffIcon'
import { default as arrow_down_order } from './ArrowDownOrder'
import { default as refresh } from './Refresh'
import { default as loading_animated } from './LoadingAnimated'
import { default as satisfaction_survey } from './SatisfactionSurveyIcon'
import { default as kebab } from './iconKebab'
import { default as jump_out } from './iconJumpOut'
import { default as micro_active } from './iconMicroActive'
import { default as chat } from './iconChat'
import { default as micro_desactive } from './iconMicroDesactive'
import { default as call_end } from './iconCallEnd'
import { default as support_contact } from './iconSupport'
import { default as micro } from './iconMicro'
import { default as setting } from './iconSetting'
import { default as video } from './iconVideo'
import { default as video_off } from './iconVideoOff'
import { default as paper_clip } from './PaperClip'
import { default as face_smile } from './FaceSmile'
import { default as icon_anex } from './iconAnex'
import { default as icon_send } from './iconSend'
import { default as models } from './Models'
import { default as copy_link } from './CopyLink'
import { default as quit_blank } from './quitBlank'
import { default as enter_blank } from './enterBlank'
import { default as drag_and_drop } from './DragAndDrop'
import { default as print } from './PrintIcon'
import { default as stop_button } from './StopButton'
import { default as maximize } from './Maximize'
import { default as file } from './File'
import { default as draft_notifications } from './DraftNotification'
import { default as draft } from './Draft'
import { default as active } from './Active'
import { default as inactive } from './Inactive'
import { default as patients } from './Patients'
import { default as bookmark } from './Bookmark'
import { default as bookmark_blue } from './BookmarkBlue'
import { default as time_notifications } from './TimeNotifications'
import { default as finance } from './Finance'
import { default as h_history } from './HHistory'

export const icons = [
  'attention_rounded',
  'attention_triangle',
  'bold_paper',
  'calendar',
  'calendar_alt',
  'camera',
  'chat_doctor',
  'chat_person',
  'check_rounded',
  'check_on',
  'check_disable',
  'check2',
  'check',
  'checked',
  'checklist_health',
  'chevron',
  'chevron_up_down',
  'chevron_open',
  'clipboard',
  'clipboard_check',
  'clipboard_patient',
  'close_rounded',
  'close',
  'doctor',
  'download',
  'edit_cc',
  'fila',
  'file',
  'file_text',
  'hamburguer',
  'hospital',
  'identity',
  'maximize',
  'models',
  'monitor_doctor',
  'monitor',
  'pencil_rounded',
  'queue',
  'reload',
  'reload_cc',
  'schedule',
  'search',
  'share',
  'share_second',
  'stethoscope',
  'surdo',
  'trash',
  'time_24',
  'time_rounded_alt',
  'time_rounded',
  'time',
  'user',
  'up_right',
  'users',
  'warning',
  'icon_edit',
  'support',
  'arrow_left',
  'arrow_right',
  'file_text',
  'reason_ear',
  'clinics',
  'pront_icon',
  'dots',
  'whats_icon',
  'edit_icon',
  'message_icon',
  'profile_icon',
  'sliders',
  'info_icon',
  'load',
  'register_icon',
  'clipboard_text',
  'pdf_icon',
  'attendence',
  'presential',
  'conf_video',
  'error_icon',
  'loading_icon',
  'send_check',
  'interrogation',
  'forward',
  'double_left_arrow',
  'logo_cockpit',
  'home',
  'prescription_icon',
  'custom_icon',
  'medical_record',
  'exam_icon',
  'check3',
  'zoom_in',
  'zoom_out',
  'sound_on',
  'sound_off',
  'external_link',
  'star_icon',
  'plus_document',
  'plus',
  'eye_on',
  'eye_off',
  'arrow_down_order',
  'refresh',
  'loading_animated',
  'satisfaction_survey',
  'kebab',
  'jump_out',
  'micro_active',
  'chat',
  'micro_desactive',
  'call_end',
  'support_contact',
  'micro',
  'setting',
  'video',
  'video_off',
  'paper_clip',
  'face_smile',
  'icon_anex',
  'icon_send',
  'copy_link',
  'quit_blank',
  'enter_blank',
  'drag_and_drop',
  'print',
  'stop_button',
  'draft_notifications',
  'draft',
  'active',
  'inactive',
  'patients',
  'bookmark',
  'bookmark_blue',
  'time_notifications',
  'finance',
  'h_history'
]

export type StringIcons =
  | 'attention_rounded'
  | 'attention_triangle'
  | 'bold_paper'
  | 'calendar'
  | 'calendar_alt'
  | 'camera'
  | 'chat_doctor'
  | 'chat_person'
  | 'check_rounded'
  | 'check_on'
  | 'check_disable'
  | 'check2'
  | 'check'
  | 'checked'
  | 'checklist_health'
  | 'chevron'
  | 'chevron_up_down'
  | 'chevron_open'
  | 'clipboard'
  | 'clipboard_check'
  | 'clipboard_patient'
  | 'close_rounded'
  | 'close'
  | 'doctor'
  | 'download'
  | 'edit_cc'
  | 'fila'
  | 'file'
  | 'file_text'
  | 'hamburguer'
  | 'hospital'
  | 'identity'
  | 'maximize'
  | 'models'
  | 'monitor_doctor'
  | 'monitor'
  | 'pencil_rounded'
  | 'queue'
  | 'reload'
  | 'reload_cc'
  | 'schedule'
  | 'search'
  | 'share'
  | 'share_second'
  | 'stethoscope'
  | 'surdo'
  | 'trash'
  | 'time_24'
  | 'time_rounded_alt'
  | 'time_rounded'
  | 'time'
  | 'user'
  | 'up_right'
  | 'users'
  | 'warning'
  | 'icon_edit'
  | 'support'
  | 'arrow_left'
  | 'arrow_right'
  | 'file_text'
  | 'reason_ear'
  | 'clinics'
  | 'pdf_icon'
  | 'pront_icon'
  | 'dots'
  | 'whats_icon'
  | 'edit_icon'
  | 'message_icon'
  | 'profile_icon'
  | 'sliders'
  | 'info_icon'
  | 'load'
  | 'register_icon'
  | 'clipboard_text'
  | 'attendence'
  | 'presential'
  | 'conf_video'
  | 'error_icon'
  | 'loading_icon'
  | 'send_check'
  | 'interrogation'
  | 'forward'
  | 'double_left_arrow'
  | 'logo_cockpit'
  | 'home'
  | 'prescription_icon'
  | 'custom_icon'
  | 'medical_record'
  | 'exam_icon'
  | 'check3'
  | 'zoom_in'
  | 'zoom_out'
  | 'sound_on'
  | 'sound_off'
  | 'external_link'
  | 'star_icon'
  | 'plus_document'
  | 'plus'
  | 'eye_on'
  | 'eye_off'
  | 'arrow_down_order'
  | 'refresh'
  | 'loading_animated'
  | 'satisfaction_survey'
  | 'kebab'
  | 'jump_out'
  | 'micro_active'
  | 'chat'
  | 'micro_desactive'
  | 'call_end'
  | 'support_contact'
  | 'micro'
  | 'setting'
  | 'video'
  | 'video_off'
  | 'paper_clip'
  | 'face_smile'
  | 'icon_anex'
  | 'icon_send'
  | 'copy_link'
  | 'quit_blank'
  | 'enter_blank'
  | 'drag_and_drop'
  | 'print'
  | 'stop_button'
  | 'draft_notifications'
  | 'draft'
  | 'active'
  | 'inactive'
  | 'patients'
  | 'bookmark'
  | 'bookmark_blue'
  | 'time_notifications'
  | 'finance'
  | 'h_history'

export default {
  attention_triangle,
  attention_rounded,
  bold_paper,
  calendar,
  calendar_alt,
  camera,
  chat_doctor,
  chat_person,
  check_rounded,
  check_on,
  check_disable,
  check2,
  check,
  checked,
  checklist_health,
  chevron,
  chevron_up_down,
  chevron_open,
  clipboard,
  clipboard_check,
  clipboard_patient,
  close_rounded,
  close,
  doctor,
  download,
  edit_cc,
  fila,
  file,
  file_text,
  hamburguer,
  hospital,
  identity,
  maximize,
  monitor_doctor,
  monitor,
  pencil_rounded,
  queue,
  reload,
  reload_cc,
  schedule,
  search,
  share,
  share_second,
  stethoscope,
  surdo,
  trash,
  time_24,
  time_rounded_alt,
  time_rounded,
  time,
  user,
  up_right,
  users,
  warning,
  icon_edit,
  support,
  arrow_left,
  arrow_right,
  reason_ear,
  clinics,
  pdf_icon,
  pront_icon,
  dots,
  whats_icon,
  edit_icon,
  message_icon,
  profile_icon,
  sliders,
  info_icon,
  load,
  register_icon,
  clipboard_text,
  attendence,
  presential,
  conf_video,
  error_icon,
  loading_icon,
  send_check,
  interrogation,
  forward,
  double_left_arrow,
  logo_cockpit,
  home,
  prescription_icon,
  custom_icon,
  medical_record,
  exam_icon,
  check3,
  zoom_in,
  zoom_out,
  sound_on,
  sound_off,
  models,
  external_link,
  star_icon,
  plus_document,
  plus,
  eye_on,
  eye_off,
  arrow_down_order,
  refresh,
  loading_animated,
  satisfaction_survey,
  kebab,
  jump_out,
  micro_active,
  chat,
  micro_desactive,
  call_end,
  support_contact,
  micro,
  setting,
  video,
  video_off,
  paper_clip,
  face_smile,
  icon_anex,
  icon_send,
  copy_link,
  quit_blank,
  enter_blank,
  drag_and_drop,
  print,
  stop_button,
  draft_notifications,
  draft,
  active,
  inactive,
  patients,
  bookmark,
  bookmark_blue,
  time_notifications,
  finance,
  h_history
}
