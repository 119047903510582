import React, { forwardRef, InputHTMLAttributes } from 'react'
import IconExporter from '../IconExporter'
import Tooltip from '../Tooltip'
import {
  RadioContainer,
  Radio,
  ErrorMessage,
  Container,
  IErrorProps,
  RadioBox
} from './styles'

export interface IRadio {
  id?: string
  value: string | number
  label: string
  infoMessage?: string
}

export interface RadioGroupProps
  extends IErrorProps,
    InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  message?: string
  name: string
  options: IRadio[]
  setValue: React.Dispatch<React.SetStateAction<string | number>>
}

/**
 * Este componente irá renderizar um RadioGroup, estende HTMLInputElement props
 * @param {boolean} error booleano que ativa a mensagem de erro
 * @param {string} message mensagem de erro
 * @param {string} name atributo name do input
 * @param {array} options array que passa as opções para RadioGroup
 * @param {SetStateAction} setValue
 * @param {string}  fontSizeError string que passa o font-size para a mensagem de erro - por default ele é 12px'
 * @param {string} fontWeight string que passa o font-weight para a mensagem de erro - por default ele regular'
 *
 * */

const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      setValue,
      name,
      error,
      options,
      message,
      fontSizeError,
      fontWeight,
      ...props
    }: RadioGroupProps,
    reference
  ) => {
    const handleChangeValue = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
      },
      [setValue]
    )

    return (
      <Container>
        <RadioContainer>
          {options.map((radio, index) => {
            const radioId = radio.id ?? `radio-element-${index}-${name}`
            return (
              <RadioBox key={radioId}>
                <Radio
                  ref={reference}
                  {...props}
                  key={radioId}
                  label={radio.label}
                  name={name}
                  value={radio.value}
                  onChange={handleChangeValue}
                  id={radioId}
                  data-testid={props['data-testid'] ?? 'radio-button'}
                  // eslint-disable-next-line eqeqeq
                  checked={props.value == radio.value}
                />
                {radio.infoMessage && (
                  <Tooltip description={radio.infoMessage}>
                    <IconExporter
                      name='info_icon'
                      data-testid='click-moreInformation'
                      iconsize='15px'
                      width='15px'
                    />
                  </Tooltip>
                )}
              </RadioBox>
            )
          })}
        </RadioContainer>
        {error && (
          <ErrorMessage fontSizeError={fontSizeError} fontWeight={fontWeight}>
            {message}
          </ErrorMessage>
        )}
      </Container>
    )
  }
)

RadioGroup.displayName = 'RadioGroup'
export default RadioGroup
