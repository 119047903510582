import React, { forwardRef, InputHTMLAttributes, useState } from 'react'
import InputMask from 'react-input-mask'
import {
  InputWrapperProps,
  InputContainer,
  InputWrapper,
  WrapperLight,
  ErrorContainer
} from './styles'
import { StringIcons } from '../../Resources/SvgComponents'
import IconExporter from '../../IconExporter'
import { FormTestId } from '../../utils/enum'

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    InputWrapperProps {
  icon?: StringIcons
  iconPosition?: 'left' | 'right'
  error?: boolean
  message?: string
  margin?: string
  mask?: string
  dataTestId?: string
  maskPlaceholder?: string
  disableTrim?: boolean
  onClickIcon?: () => void
  v7?: boolean
}
/**
 * Este componente irá renderizar um Input, estende HTMLInputElement props
 * @param {StringIcons} icon Exibe um ícone no lado direito do input
 * @param {string} iconPosition Propriedade usada para controlar a posição do ícone dentro do Input.
 * @param {boolean} error Necessário para quando exibir a mensagem de erro e a borda em vermelho.
 * @param {string} message Mensagem de erro.
 * @param {string} mask Máscara que irá formatar o texto.
 *
 * Ex: 999.999.999-99 irá formatar um CPF.
 * @param {string} maskPlaceholder Só irá ativar caso utilize a prop mask junto. Cria um placeholder de máscara.
 * @param {string} margin
 * @param {boolean} disableTrim Desabilita a formatação de trim que remove espaços a mais digitados no Input.
 * @param {boolean} v7 Propriedade mantida para não gerar conflito em projetos que a utilizam.
 *
 * Poderá ser removida em uma atualização futura.
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      icon,
      iconPosition = 'right',
      error,
      message,
      margin,
      mask,
      value,
      maskPlaceholder,
      onChange,
      disableTrim,
      capture,
      onClickIcon,
      dataTestId,
      ...props
    }: InputProps,
    ref
  ) => {
    const [active, setActive] = useState(false)

    const trimEventValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.trimStart().replace(/\s\s+/g, ' ')
    }

    return (
      <InputContainer margin={margin}>
        <WrapperLight
          direction={props.direction}
          error={error}
          active={active || undefined}
          iconPosition={iconPosition}
        >
          {mask ? (
            <InputMask
              {...props}
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActive(true)
                !disableTrim && trimEventValue(event)
                onChange && onChange(event)
              }}
              onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                setActive(true)
                props.onFocus && props.onFocus(event)
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                setActive(false)
                props.onBlur && props.onBlur(event)
              }}
              maskPlaceholder={maskPlaceholder ?? null}
              mask={mask}
              alwaysShowMask
            >
              <InputWrapper
                data-testid={dataTestId ?? FormTestId.INPUT_MASK}
                ref={ref}
                errors={error}
                active={active}
                icon={icon}
                iconPosition={iconPosition}
              />
            </InputMask>
          ) : (
            <InputWrapper
              data-testid={dataTestId ?? FormTestId.INPUT}
              {...props}
              ref={ref}
              value={value}
              errors={error}
              active={active}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setActive(true)
                !disableTrim && trimEventValue(event)
                onChange && onChange(event)
              }}
              onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                setActive(true)
                props.onFocus && props.onFocus(event)
              }}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                setActive(false)
                props.onBlur && props.onBlur(event)
              }}
              icon={icon}
              iconPosition={iconPosition}
            />
          )}
          <IconExporter onClick={onClickIcon} iconsize={20} name={icon} />
        </WrapperLight>
        {error && <ErrorContainer>{message}</ErrorContainer>}
      </InputContainer>
    )
  }
)

Input.displayName = 'Input'
export default Input
