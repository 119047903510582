import { theme } from '../Theme'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 847px) {
    padding: 32px 32px 0 32px;
  }
`

export const Title = styled.div`
  font-weight: ${theme.fontWeight.bold};
  font-size: 20px;
  @media (max-width: 847px) {
    font-size: 16px;
    color: #545e6c;
  }
`

export const Content = styled.div`
  width: 85%;
  margin-top: 16px;
  color: ${theme.palette.gray4};
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 500px) {
    font-size: 14px;
    width: 100%;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
  button {
    margin-left: 10px;
  }
  @media (max-width: 847px) {
    flex-direction: column-reverse;
    margin-top: 0;
    padding: 24px;
    button {
      margin-bottom: 16px;
      margin-left: 0;
    }
  }
`

export const IconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 16px;
`
