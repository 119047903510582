import styled from 'styled-components'

import { theme } from '../../Theme'

interface RadioWrapper {
  error?: boolean
  disableUppercase?: boolean
}

export const Wrapper = styled.div`
  display: flex;
`

export const RadioWrapper = styled.label<RadioWrapper>`
  display: flex;
  align-items: center;

  input[type='radio'] {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: ${theme.colors.white};
    border: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    box-shadow: inset 0 0 0 1px ${theme.colors.gray5};
    appearance: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 1px ${theme.colors.blue3};
    }

    &:checked:not(:disabled) {
      box-shadow: inset 0 0 0 4px ${theme.colors.white};
      background: ${theme.colors.blue3};
      border: 1px solid ${theme.colors.blue3};
    }

    &:disabled {
      background: ${theme.colors.gray6};
      box-shadow: inset 0 0 0 1px ${theme.colors.gray5} !important;
      &,
      & + label {
        cursor: default;
        opacity: 0.5;
      }
    }

    &:checked:not(:disabled) + label {
      color: ${theme.colors.blue3};
    }
  }

  label {
    cursor: pointer;
    position: relative;
    margin-left: ${theme.spacing.xSmall};
    color: ${({ error }) => (error ? theme.colors.red1 : theme.colors.gray5)};
  }

  &:hover > input {
    box-shadow: inset 0 0 0 1px ${theme.colors.blue3};
  }
`

export const RadioElement = styled.input`
  margin: 0;
  width: 20px;
  height: 20px;
`

export const RadioLabel = styled.label<RadioWrapper>`
  font-size: 12px;
  text-transform: ${({ disableUppercase }) =>
    disableUppercase ? 'none' : 'uppercase'};
  font-weight: ${theme.fontWeight.regular};
  display: flex;
  align-items: center;
  user-select: none;
`
