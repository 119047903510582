import { intervalToDuration, yearsToMonths } from 'date-fns'

enum EMaxAge {
  maxAge = 150
}

export const getAgeByBirthDate = (
  date: string
): string | number | undefined => {
  if (!date) return undefined

  const today = new Date()
  const birthDate = new Date(date)

  if (birthDate.getFullYear() === 0 || today.getFullYear() === 0) {
    return undefined
  }

  let age = today.getFullYear() - birthDate.getFullYear()

  if (age > EMaxAge.maxAge) return undefined

  const month = today.getMonth() - birthDate.getMonth()
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  if (age >= 0 && age < 2) {
    return underTwoYears(birthDate, today)
  }

  return age ? `${age} ano${age === -1 ? '' : 's'}` : undefined
}

export const underTwoYears = (
  birthDate: Date,
  today: Date
): string | number | undefined => {
  const interval = intervalToDuration({
    start: birthDate,
    end: today
  })
  const transformYearsToMonth = interval?.years
    ? yearsToMonths(interval.years) + Number(interval.months)
    : interval?.months

  if (transformYearsToMonth && Number(interval.days) === 0) {
    return `${transformYearsToMonth} ${
      transformYearsToMonth === 1 ? 'mês' : 'meses'
    }`
  }

  if (interval.days && Number(transformYearsToMonth) === 0) {
    return `${interval.days} dia${interval.days === 1 ? '' : 's'}`
  }

  if (transformYearsToMonth || interval.days) {
    return `${transformYearsToMonth} meses e ${interval.days} dia${
      interval.days === 1 ? '' : 's'
    }`
  }

  return undefined
}
