import styled, { css } from 'styled-components'
import { theme } from '../../Theme'

interface showFilter {
  show: boolean
  direction?: 'left' | 'right'
  width?: string
}

interface IRowButtons {
  minFilter: boolean
}

interface showCheckBox {
  show: boolean
  disabled?: boolean
  removeScroll?: boolean
}

const scroll = css`
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px ${theme.colors.gray4};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray4};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: ${theme.colors.gray6};
  }
`
const scrollModal = css`
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px ${theme.colors.gray4};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray4};
  }

  &::-webkit-scrollbar-track-piece {
    background-color: ${theme.colors.gray6};
  }
`

export const ModalContainer = styled.div`
  > div {
    overflow-x: initial;
    overflow-y: initial;
  }
`

export const Modal = styled.div<showFilter>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  max-height: 500px;
  width: ${(props) => props.width};
  position: absolute;
  top: calc(100% + ${theme.spacing.base});
  ${({ direction }) =>
    direction === undefined || direction === 'left'
      ? 'left: 0px'
      : 'right: 0px'};
  padding: ${theme.spacing.large}
    calc(${theme.spacing.large} + ${theme.spacing.xSmall});
  box-shadow: 0px 13px 20px ${theme.colors.black}29;
  background-color: #fff;
  z-index: 800;
  border-radius: ${theme.borderRadius.modal};
  flex-direction: column;

  @media (max-width: 1100px) {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    min-width: 50vw;
    max-width: 80vw;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media (max-width: 750px) {
    top: 0;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    min-width: 100vw;
    padding: 0 calc(${theme.spacing.large} + ${theme.spacing.xSmall});
    overflow-y: auto;
    ${scrollModal}
  }
`

export const Header = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: flex;
  }
  align-items: center;
  justify-content: flex-end;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid ${theme.colors.gray3};
  margin-bottom: 30px;
  button {
    width: 35px;
    height: 70%;
    background: transparent;
    border: none;
  }
`

export const ColumnCheckBox = styled.div<showCheckBox>`
  display: ${({ show }) => (show ? 'none' : 'flex')};
  flex-direction: column;
  row-gap: ${theme.spacing.base};
  width: 100%;
  max-width: 203px;
  max-height: 328px;
  padding-bottom: 10px;
  overflow: auto;
  ${({ disabled }) =>
    disabled &&
    css`
      span {
        opacity: 0.5;
        cursor: not-allowed;
        * {
          cursor: not-allowed;
        }
      }
    `};
  @media (max-width: 750px) {
    margin-bottom: 20px;
    max-width: 100%;
  }
  ${scroll}
`

export const ColumnForm = styled.div<showCheckBox>`
  display: flex;
  flex-flow: wrap;
  gap: ${theme.spacing.medium};
  width: 100%;
  max-height: 328px;
  margin-left: ${({ show }) => (show ? '' : theme.spacing.base)};
  ${({ removeScroll }) =>
    !removeScroll &&
    css`
      ${scroll}
    `}

  @media (max-width: 750px) {
    margin-left: 0;
    margin-top: 20px;
    min-height: 100px;
  }
`

export const TitleFilter = styled.div`
  width: 100%;
  font: ${theme.fontWeight.medium + ' 13px ' + theme.font.root};
  text-transform: uppercase;
`

export const RowButtons = styled.div<IRowButtons>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: ${theme.spacing.base};
  border-top: 1px solid ${theme.colors.gray3};
  padding-top: 25px;
  button {
    width: 116px;
    height: 40px;
  }
  > div {
    display: flex;
    column-gap: ${theme.spacing.base};
  }
  span {
    height: 40px;
    text-align: center;
    color: ${theme.colors.blue3};
    font: ${theme.fontWeight.semibold + ' 14px ' + theme.font.root};
    cursor: pointer;
    padding-top: 10px;
    width: ${({ minFilter }) => (minFilter ? 'fit-content' : '100%')};
    margin-right: ${({ minFilter }) => (minFilter ? '30px' : '0')};
    @media (max-width: 750px) {
      flex-direction: column-reverse;
      margin-top: auto;
      margin-bottom: 30px;
      align-items: center;
      width: 100%;
      margin-right: 0px;
    }
  }

  @media (max-width: 750px) {
    flex-direction: column-reverse;
    margin-top: auto;
    margin-bottom: 30px;
    align-items: center;
  }
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`
