import React from 'react'

const SvgBookmarkBlue = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version='1.1'
    id='Camada_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 24 24'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {`.st1{fill:#1C7CF9;stroke:#1C7CF9;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;}`}
    </style>
    <path
      className='st1'
      d='M5,19.5V7c0-1.7,1.3-3,3-3h8c1.7,0,3,1.3,3,3v12.5c0,0.2-0.1,0.4-0.3,0.4c-0.2,0.1-0.4,0.1-0.5,0l-5.9-4.2
      c-0.2-0.1-0.4-0.1-0.6,0l-5.9,4.2c-0.2,0.1-0.4,0.1-0.5,0C5.1,19.9,5,19.7,5,19.5z'
    />
  </svg>
)

export default SvgBookmarkBlue
