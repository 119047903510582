import styled, { css } from 'styled-components'
import { theme } from '../Theme'

interface TooltipWrapper {
  show: boolean
  position?: 'top' | 'left' | 'right' | 'bottom'
  background?: string
  custom?: boolean
  opacity?: number
  isVisible?: boolean
  positionX: 'right' | 'left' | null
  positionY: 'top' | 'bottom' | null
  delay: number
  hasArrow?: boolean
  margin?: string
}

const pos = {
  top: css`
    bottom: calc(100% + 5px);
  `,
  bottom: css`
    top: calc(100% + 5px);
  `,
  left: css`
    right: calc(100% + 5px);
    top: 0;
    bottom: 0;
  `,
  right: css`
    left: calc(100% + 5px);
    top: 0;
    bottom: 0;
  `
}
interface TooltipCallProps {
  cursor?: string
}
export const TooltipCall = styled.span<TooltipCallProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: ${({ cursor }) => cursor || 'default'};
`

export const TooltipTemplate = styled.span`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`

export const TooltipWrapper = styled.span<TooltipWrapper>`
  border-radius: 6px;
  padding: ${({ custom, hasArrow }) =>
    hasArrow ? '0px 24px' : !custom ? '12px' : '0px'};
  margin: ${({ margin }) => margin && margin};
  height: ${({ hasArrow }) => hasArrow && '34px'};
  width: max-content;

  transition: ${({ delay }) => `all ${delay}s ease-in`};
  background: ${(props) =>
    !props.background ? theme.colors.black : props.background};

  display: ${({ isVisible }) => (isVisible ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  opacity: ${({ show, opacity }) =>
    show && !opacity ? '1' : show && opacity ? opacity : '0'};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  z-index: 100;

  max-width: 60vw;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  position: absolute;
  ${({ position, positionY }) =>
    positionY ? pos[positionY] : position ? pos[position] : pos.bottom};

  ${({ positionX }) =>
    positionX === 'left'
      ? css`
          left: 0px;
        `
      : positionX === 'right' &&
        css`
          right: 0px;
        `}
`

export const TooltipText = styled.span<{ textMaxWidth?: string }>`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeight.regular};
  font-size: 14px;
  line-height: 20px;
  ${(props) =>
    props.textMaxWidth &&
    css`
      max-width: ${props.textMaxWidth};
    `}
`

const arrowPosition = (background: string | undefined) => ({
  bottom: css`
    bottom: 100%;
    border-bottom-color: ${background};
  `,
  top: css`
    top: 100%;
    border-top-color: ${background};
  `,
  right: css`
    right: 100%;
    border-right-color: ${background};
  `,
  left: css`
    left: 100%;
    border-left-color: ${background};
  `
})

interface ITooltipArrowProps {
  position?: 'top' | 'left' | 'right' | 'bottom'
  background?: string
}

export const TooltipArrow = styled.div<ITooltipArrowProps>`
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 5px;
    border-color: transparent;

    ${({ position, background }) =>
      position
        ? arrowPosition(background || theme.colors.black)[position]
        : 'display: none;'}

    ${({ position }) =>
      (position === 'left' || position === 'right') &&
      `
        top: 50%;
        margin-top: -5px;
      `}

    ${({ position }) =>
      (position === 'bottom' || position === 'top') &&
      `
        left: 50%;
        margin-left: -5px;
      `};
  }
`
