import React, { forwardRef, InputHTMLAttributes } from 'react'
import { UseFormRegister, FieldValues } from 'react-hook-form'
import { RadioElement, RadioLabel, RadioWrapper } from './styles'

export interface IRadio extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: boolean
  register?: UseFormRegister<FieldValues>
  checked?: boolean
  className?: string
  disableUppercase?: boolean
  dataTestid?: string
}

/**
 * Este componente irá renderizar um Radio Button, estende HTMLInputElement props
 * @param {string} id Recebe o id do radio (cada botão precisa de um id diferente para sofrer ações individuais)
 * @param {string} label Texto que será exibido ao lado do botão.
 * @param {string | number | readonly string[]} value Valor do radio button que será passado no submit.
 * @param {error} error Valor do radio button que será passado no submit.
 * @param {UseFormRegister<FieldValues>} register recebe o name e parâmetros do react-hook-form.
 * @param {boolean}  disableUppercase Desabilita a transformação da label para uppercase.
 *
 */

export const Radio = forwardRef<HTMLInputElement, IRadio>(
  (
    { label, error, className, dataTestid, disableUppercase = false, ...props },
    reference
  ) => {
    return (
      <RadioWrapper className={className} htmlFor={props.id} error={error}>
        <RadioElement id={props.id} type='radio' {...props} ref={reference} />
        <RadioLabel
          data-testid={dataTestid}
          error={error}
          htmlFor={props.id}
          disableUppercase={disableUppercase}
        >
          {label}
        </RadioLabel>
      </RadioWrapper>
    )
  }
)
