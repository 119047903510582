import React, { forwardRef, TextareaHTMLAttributes, useState } from 'react'
import { UseFormRegister, FieldValues } from 'react-hook-form'
import {
  Container,
  ErrorTextAreaIcon,
  Field,
  Message,
  WrapperLight
} from './styles'
import { StringIcons, IconExporter } from '../../'

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  icon?: StringIcons
  register?: UseFormRegister<FieldValues>
  error?: string | boolean
  children?: never
  errorMessage?: string
  large?: boolean
  disabled?: boolean
  v7?: boolean
  onValueChange?: (event: unknown) => void
  enableTrim?: boolean
  hideIconError?: boolean
  dataTestId?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      register,
      error,
      icon,
      enableTrim,
      large,
      errorMessage,
      disabled,
      v7,
      onValueChange,
      hideIconError,
      dataTestId,
      ...props
    }: TextAreaProps,
    reference
  ) => {
    const [active, setActive] = useState<boolean>(false)
    return (
      <Container>
        <WrapperLight error={error} active={active}>
          <Field
            data-testid={dataTestId}
            ref={reference}
            {...props}
            large={large}
            onChange={(e) => {
              props.onChange?.(e)
              onValueChange?.(
                enableTrim ? e.target.value.trimStart() : e.target.value
              )
            }}
            onFocus={(e) => {
              props.onFocus?.(e)
              setActive(true)
            }}
            onBlur={(e) => {
              props.onBlur?.(e)
              setActive(false)
            }}
            error={!!error}
            active={active}
            disabled={disabled}
          />
          {!hideIconError && error && (
            <ErrorTextAreaIcon>
              <IconExporter name='warning' iconsize={20} />
            </ErrorTextAreaIcon>
          )}
        </WrapperLight>
        {error && (
          <Message error={!!error}>
            {!errorMessage ? 'Campo obrigatório' : errorMessage}
          </Message>
        )}
      </Container>
    )
  }
)

TextArea.displayName = 'TextArea'

export { TextArea }
