import React from 'react'

type ElementRefType = React.MutableRefObject<HTMLElement | null>
/**
 * Custom hook to observe if the SelectOptions is visible
 * on the viewport and determine if will open Up or Down.
 *
 * @param elementRef - Reference to the observable element
 * @param enabled - Enable custom hook
 */
const useOptionsDirection = (elementRef: ElementRefType, enabled?: boolean) => {
  const [direction, setDirection] = React.useState<'up' | 'down'>('down')

  const updateDirection = () =>
    setDirection(direction === 'down' ? 'up' : 'down')

  React.useEffect(() => {
    if (!elementRef?.current || !enabled) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          updateDirection()
        }
      },
      {
        threshold: 1
      }
    )

    observer.observe(elementRef.current)

    return () => {
      observer.disconnect()
    }
  }, [elementRef.current])

  return direction
}

export default useOptionsDirection
